import { Stack, Typography, Button } from '@mui/material';
import { Image, Wrapper } from '@scorenco/components';
import { RouteLink } from '@scorenco/core';

import { RouteKey } from '../../routes';
import { PageSeo } from '../Seo';

export const ErrorComponent = () => {
  return (
    <Wrapper>
      <PageSeo
        title="Page introuvable"
        description="Erreur 404, cette page n'existe plus, ou a changé d'adresse"
        url="error"
      />
      <Stack
        justifyContent="center"
        alignItems="center"
        gap={2}
        direction={'row'}
        width={'80%'}
        margin={'auto'}
      >
        <Stack
          justifyContent="center"
          alignItems="left"
          gap={2}
          my={4}
          minHeight="25rem"
          textAlign={'left'}
          width={'60%'}
        >
          <Typography variant="h1">Erreur 404</Typography>
          <Typography variant="h3">Vous êtes hors-jeu !</Typography>
          <Typography variant="subtitle1" mb={2}>
            La page que vous cherchez n’est <strong>pas disponible</strong>.
            <br />
            Utilisez la recherche pour retrouver votre chemin en cliquant sur le{' '}
            <strong>bouton</strong> ci-dessous.
            <br />
            Ou <strong>contactez-nous</strong> si le problème persiste.
          </Typography>
          <RouteLink href={{ routeKey: RouteKey.Home }}>
            <Button variant="contained">Retour à la page d'accueil</Button>
          </RouteLink>
        </Stack>

        <Stack justifyContent="center" alignItems="center" width={'40%'}>
          <Image src="/images/404.png" width={'15rem'} />
        </Stack>
      </Stack>
    </Wrapper>
  );
};
