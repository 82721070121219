/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    GenericMorph: [
      'ComponentFeatureListFeature',
      'ComponentKeysFiguresKeyFigure',
      'ComponentLinkListLink',
      'ComponentPricingProduct',
      'ComponentReviewsReview',
      'ComponentSharedButton',
      'ComponentSharedMenuItem',
      'ComponentSharedMetaSocial',
      'ComponentSharedSeo',
      'ComponentSharedSubMenuItem',
      'ComponentSlicesAdvertising',
      'ComponentSlicesButton',
      'ComponentSlicesFaq',
      'ComponentSlicesFeaturesList',
      'ComponentSlicesHero',
      'ComponentSlicesHtml',
      'ComponentSlicesImage',
      'ComponentSlicesImageWithText',
      'ComponentSlicesKeysFigures',
      'ComponentSlicesLinkList',
      'ComponentSlicesLivesSlider',
      'ComponentSlicesNewsletter',
      'ComponentSlicesPricing',
      'ComponentSlicesReviews',
      'ComponentSlicesSection',
      'ComponentSlicesSportList',
      'ComponentSlicesStoreGrade',
      'ComponentSlicesTabEnd',
      'ComponentSlicesTabStart',
      'ComponentSlicesTeam',
      'ComponentSlicesTestimonial',
      'ComponentSlicesText',
      'ComponentSlicesVerticalTab',
      'ComponentTeamMember',
      'Configuration',
      'I18NLocale',
      'MenuV5',
      'MenusMenu',
      'MenusMenuItem',
      'PagesChHome',
      'PagesClub',
      'PagesHome',
      'PagesLanding',
      'PagesLive',
      'PagesSport',
      'UploadFile',
      'UploadFolder',
    ],
    MenuV5MenuDynamicZone: ['ComponentSharedMenuItem', 'Error'],
    PagesChHomeBodyDynamicZone: [
      'ComponentSlicesAdvertising',
      'ComponentSlicesFaq',
      'ComponentSlicesFeaturesList',
      'ComponentSlicesImage',
      'ComponentSlicesImageWithText',
      'ComponentSlicesLinkList',
      'ComponentSlicesLivesSlider',
      'ComponentSlicesNewsletter',
      'ComponentSlicesSection',
      'ComponentSlicesSportList',
      'ComponentSlicesStoreGrade',
      'ComponentSlicesTabEnd',
      'ComponentSlicesTabStart',
      'ComponentSlicesTestimonial',
      'ComponentSlicesText',
      'ComponentSlicesVerticalTab',
      'Error',
    ],
    PagesHomeBodyDynamicZone: [
      'ComponentSlicesAdvertising',
      'ComponentSlicesFaq',
      'ComponentSlicesFeaturesList',
      'ComponentSlicesImage',
      'ComponentSlicesImageWithText',
      'ComponentSlicesLinkList',
      'ComponentSlicesLivesSlider',
      'ComponentSlicesNewsletter',
      'ComponentSlicesReviews',
      'ComponentSlicesSection',
      'ComponentSlicesSportList',
      'ComponentSlicesStoreGrade',
      'ComponentSlicesTabEnd',
      'ComponentSlicesTabStart',
      'ComponentSlicesTestimonial',
      'ComponentSlicesText',
      'ComponentSlicesVerticalTab',
      'Error',
    ],
    PagesLandingBodyDynamicZone: [
      'ComponentSlicesAdvertising',
      'ComponentSlicesFaq',
      'ComponentSlicesFeaturesList',
      'ComponentSlicesHero',
      'ComponentSlicesHtml',
      'ComponentSlicesImage',
      'ComponentSlicesImageWithText',
      'ComponentSlicesKeysFigures',
      'ComponentSlicesLinkList',
      'ComponentSlicesLivesSlider',
      'ComponentSlicesNewsletter',
      'ComponentSlicesPricing',
      'ComponentSlicesReviews',
      'ComponentSlicesSection',
      'ComponentSlicesSportList',
      'ComponentSlicesStoreGrade',
      'ComponentSlicesTabEnd',
      'ComponentSlicesTabStart',
      'ComponentSlicesTeam',
      'ComponentSlicesTestimonial',
      'ComponentSlicesText',
      'ComponentSlicesVerticalTab',
      'Error',
    ],
    PagesLiveBodyDynamicZone: [
      'ComponentSlicesAdvertising',
      'ComponentSlicesFaq',
      'ComponentSlicesFeaturesList',
      'ComponentSlicesHero',
      'ComponentSlicesImage',
      'ComponentSlicesImageWithText',
      'ComponentSlicesLinkList',
      'ComponentSlicesLivesSlider',
      'ComponentSlicesNewsletter',
      'ComponentSlicesSection',
      'ComponentSlicesStoreGrade',
      'ComponentSlicesTabEnd',
      'ComponentSlicesTabStart',
      'ComponentSlicesTeam',
      'ComponentSlicesTestimonial',
      'ComponentSlicesText',
      'ComponentSlicesVerticalTab',
      'Error',
    ],
    PagesSportBodyDynamicZone: [
      'ComponentSlicesAdvertising',
      'ComponentSlicesButton',
      'ComponentSlicesFaq',
      'ComponentSlicesFeaturesList',
      'ComponentSlicesHtml',
      'ComponentSlicesImage',
      'ComponentSlicesImageWithText',
      'ComponentSlicesKeysFigures',
      'ComponentSlicesLinkList',
      'ComponentSlicesLivesSlider',
      'ComponentSlicesNewsletter',
      'ComponentSlicesSection',
      'ComponentSlicesSportList',
      'ComponentSlicesStoreGrade',
      'ComponentSlicesTabEnd',
      'ComponentSlicesTabStart',
      'ComponentSlicesTestimonial',
      'ComponentSlicesText',
      'ComponentSlicesVerticalTab',
      'Error',
    ],
  },
};
export default result;
