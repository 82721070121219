import { useEffect } from 'react';

import {
  AuthentificationStatus,
  useAmplifyServiceContext,
  useRouteNavigation,
  useRouteInfo,
} from '@scorenco/core';

import { NextAmplifyServiceOptions } from './types';

/**
 * Permet de rediriger l'utilisateur s'il a besoin d'être authentifié pour accéder à cette page.
 */
export const useAuthRedirection = ({
  protectedPage = true,
  state = 'sign-in',
}: NextAmplifyServiceOptions) => {
  const { status } = useAmplifyServiceContext();
  const routeNavigation = useRouteNavigation();
  const routeInfo = useRouteInfo();
  const isNotAuth = status === AuthentificationStatus.NotAuthenticated;

  useEffect(() => {
    // On redirige vers la page de connection si l'utilisateur n'est pas connecté
    // et que l'initialisation est terminée.
    if (status === AuthentificationStatus.NotAuthenticated && protectedPage) {
      routeNavigation.push({
        href: {
          routeKey: 'Connect',
          params: {
            redirectTo: routeInfo.asPath,
            state,
          },
        },
      });
    }
  }, [status, protectedPage, state, routeInfo.asPath]);

  return {
    // On considère que le rendu peut se faire si la page est public ou que l'initilisation de
    // l'authentification a été faites et que l'utilisateur est bien connecté.
    isReady:
      !protectedPage ||
      (status !== AuthentificationStatus.Loading && !isNotAuth),
  };
};
