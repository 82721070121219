import { OverrideComponent, MuiComponents } from './types';

export const MuiTextField: OverrideComponent = () => {
  const MuiTextField: MuiComponents['MuiTextField'] = {
    defaultProps: {
      fullWidth: true,
    },
    styleOverrides: {
      root: {
        '&.Mui-focused .MuiInputBase-input': {
          boxShadow: '0 0 0 100px #ffffff inset',
          textFillColor: '#266798',
        },
        '& .MuiInputBase-input:not(:placeholder-shown)': {
          boxShadow: '0 0 0 100px #ffffff inset',
          textFillColor: '#266798',
        },
      },
    },
  };

  return {
    MuiTextField,
  };
};
