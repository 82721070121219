import { useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';

import { Types } from '@/types';

declare global {
  interface Window {
    Tally?: {
      openPopup: (formId: string, options: any) => void;
    };
  }
}

import { userSession } from '../../helpers';
import { CrispService } from '../crisp';

import { getAuthClient } from './auth-client';
import { GetCurrentUserInfoDocument } from './useCurrentUserInfo.query.generated';

export const useCurrentUserInfo = () => {
  const [getUserInfo] = useLazyQuery(GetCurrentUserInfoDocument);

  return useMemo(() => {
    const getCurrentUserInfo = async (): Promise<{
      info: Types.Users_User;
      jwtToken: string;
    }> => {
      const { fetchAuthSession } = await getAuthClient();

      // Récupère le jwt token
      const session = await fetchAuthSession();
      const jwtToken = session.tokens?.idToken?.toString();
      const originalJwtToken = userSession.getToken();

      if (!jwtToken) {
        throw new Error("Can't retrieve jwt token");
      }

      userSession.setToken(jwtToken ? `Bearer ${jwtToken}` : '');

      // Récupère les infos utilisateur
      const result = await getUserInfo();
      const user = result.data?.users_user?.[0];

      if (!user) {
        userSession.setToken(originalJwtToken);
        throw new Error("Can't retrieve user");
      }

      if (user) {
        CrispService.setUserInfo(user);

        // Ouvre la popup Tally avec l'email de l'utilisateur en hiddenFields.
        // Tally permet de réaliser des enquêtes. Changer l'id par l'id de l'enquête.
        // if (typeof window !== 'undefined' && window.Tally) {
        //   window.Tally.openPopup('woKQEx', {
        //     layout: 'modal',
        //     width: 600,
        //     emoji: {
        //       text: '👋',
        //       animation: 'wave',
        //     },
        //     doNotShowAfterSubmit: true,
        //     hiddenFields: {
        //       email: user?.email,
        //     },
        //   });
        // }
      }

      userSession.setIsSuperUser(user.is_superuser);

      return {
        info: user,
        jwtToken,
      };
    };

    return { getCurrentUserInfo };
  }, []);
};
