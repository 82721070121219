import { Stack, StackProps } from '@mui/material';

type ScoreProps = Omit<StackProps, 'children'> & {
  score: number;
  opponentScore: number;
  isWinner?: boolean;
};

export const Score = ({
  score,
  opponentScore,
  isWinner,
  sx,
  ...rest
}: ScoreProps) => {
  if (
    score === null ||
    score === undefined ||
    opponentScore === null ||
    opponentScore === undefined
  ) {
    return null;
  }

  const { fontSize, width } =
    score >= 100 || opponentScore >= 100
      ? { fontSize: '3.8rem', width: '2.5rem' }
      : score >= 10 || opponentScore >= 10
        ? { fontSize: '4rem', width: '1.75rem' }
        : score <= 9 || opponentScore <= 9
          ? { fontSize: '3.8rem', width: '1.75rem' }
          : { fontSize: '4rem', width: '1.75rem' };

  return (
    <Stack
      sx={{
        width,
        height: '2rem',
        mr: '0.125rem',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: (theme) => theme.vars.font.body1,
        fontWeight: 700,
        borderRadius: '0.25rem',
        backgroundColor: '#f5f5f5',
        color: 'text.primary',
        fontSize,
        '&.is-winner': {
          color: 'secondary.main',
        },
        ...sx,
      }}
      className={[isWinner && 'is-winner'].join(' ')}
      {...rest}
    >
      <svg
        display="block"
        width="100%"
        height="100%"
        viewBox={`0 0 ${41 * String(score).length + 20} 100`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <text
          style={{ fontSize: 'inherit' }}
          fill="currentColor"
          x="50%"
          y="50%"
          dominantBaseline="central"
          textAnchor="middle"
        >
          {score}
        </text>
      </svg>
    </Stack>
  );
};
